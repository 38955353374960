/** GLOBAL STYLES **/

* {
    box-sizing: border-box
}

body {
    margin: 0;
    width: 100%;
    overflow-x: hidden;
    font-family: "inter", sans-serif, Arial, Helvetica;
}

.font-marketing-colour {
    color: #036b81;
}

.font-marketing-fontlarge {
    font-size: larger;
    padding: 20px !important;
}

.flex-parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-parent-drop-vertical {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.btn {
    background-color: #036b81;
    color: #fff;
    padding: 5px;
    border: 5px solid #036b81;
    border-radius: 5px;
    text-decoration: none;
}

/** USEFUL - UTLITY CLASSES */

.align-center {
    text-align: center;
}

.sticky {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 20px;
    /* Stick to the top of the viewport */
    z-index: 1000;
    /* To ensure it's on top of other content */
}


@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-in {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


@media all and (max-width: 1000px) {
    .flex-parent {
        justify-content: space-between;
    }

    .flex-parent-drop-vertical {
        flex-direction: column;
    }
}


/** ITEM/PAGE SPECIFIC STYLES **/

/** HEADER SECTION **/
.header {
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 2rem;
    position: fixed;
    width: 100vw;
    z-index: 100;
    top: 0;
}

.header.pulldown {
    height: fit-content;
}

.header .header-img {
    background: #ffffff;
    border: 5px solid #ffffff;
    border-radius: 5px;
    padding-right: 10px;
}

.header .header-title {
    height: 70px;
}

.header #headerMenuNavContainer {
    padding: 0;
    margin: 0;
    gap: 1rem;
    align-items: end;
    background: #ffffff;
    border: 5px solid #ffffff;
    border-radius: 5px;
    padding-left: 10px;
}

.header a {
    font-family: system-ui;
    height: 100%;
    font-size: 1.5rem;
    display: flex;
    color: #7A7A7A;
    font-weight: lighter;
    text-decoration: none;
    padding: .25rem;
}

.header #headerMenuNavContainer div {
    display: inline-block;
    padding: 5px;
    border-bottom: 4px solid transparent;
}

.header #headerMenuNavContainer div.active {
    border-bottom: 4px solid #036b81;
}

.header #headerMenuNavContainer div:hover {
    border-bottom: 4px solid #036b81;
}

.header #headerMenuIconContainer {
    display: none;
    border: 2px solid #fff;
    border-radius: 2px;
    margin: 5px;
    width: 30px;
    transition: background-color 2s, border-color 2s;
}

.header #headerMenuIcon {
    width: 15px;
    height: 15px;
    display: block;
    border: none;
    padding: 0px;
    margin: 5px;
    background: linear-gradient(to bottom,
            #333, #333 12%,
            #fff 10%, #fff 50%,
            #333 50%, #333 60%,
            #fff 60%, #fff 90%,
            #333 90%, #333 100%);
}

@media all and (max-width: 1000px) {
    .header {
        flex-direction: column;
        height: 53px;
        overflow: hidden;
        transition: height 1s, background-color 2s, border-bottom 3s;
        background: #ffffff;
        border-bottom: 3px solid #7A7A7A;
        padding-top: 2px;
    }

    .header .header-img {
        background: transparent;
        border: 0;
        border-radius: 0;
    }

    .header .header-title {
        height: 50px;
    }

    .header a {
        font-weight: bolder;
    }

    .header.pulldown {
        height: 500px;
        background-color: #EEF2F8;
        border-bottom: 5px solid #333;
        padding-bottom: 15px;
    }

    .header.pulldown #headerMenuIcon {
        background: linear-gradient(to bottom,
                #333, #333 12%,
                #fff 10%, #fff 50%,
                #333 50%, #333 60%,
                #fff 60%, #fff 90%,
                #333 90%, #333 100%);
    }

    .header #headerMenuNavContainer div {
        border-bottom: 4px solid #EEF2F8;
    }

    .header #headerMenuNavContainer {
        flex-direction: column;
        align-items: center;
        background: transparent;
        border: none;
        padding-left: 0;
    }

    .header #headerMenuIconContainer {
        display: block;
    }

    .header.pulldown #headerMenuIconContainer {
        border-color: #333;
        background: #ffffff;
    }
}


/** MAIN SECTION **/

.main {
    margin-top: 50px;
    min-height: 90vh;
    padding: 1rem;
    overflow: hidden;
}

.main.background {
    /* Fallback for older browsers */
    background-color: #ffffff;
    /* For modern browsers */
    background-image: linear-gradient(350deg, #036b81 5%, #ffffff, #ffffff, #ffffff);
    /* Ensures proper rendering */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#036b81', endColorstr='#ffffff', GradientType=0);
}

.main-container {
    min-height: 90vh;
}


/** FOOTER SECTION **/

.footer {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 1rem;
}

.footer a {
    height: 100%;
    font-size: 14px;
    display: flex;
    color: inherit;
    text-decoration: none;
    align-items: center;
    padding: .25rem;
}

.footer ul {
    display: flex;
    padding: 0;
    margin: auto;
    list-style: none;
    gap: 1rem;
    font-family: system-ui;
    flex-wrap: wrap;
    justify-content: space-around;
}

.footer .social-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.footer .fa {
    font-size: 30px;
    padding-right: 10px;
    color: #036b81;
}


/** HOME PAGE **/

.home {
    display: grid;
    grid-template-areas:
        'home-section-statement-1 home-section-statement-1 home-section-image'
        'home-section-statement-2 home-section-statement-3 home-section-image';
    grid-gap: 35px;
    height: 100%;
    margin-top: 20px;
}

.home-section-statement {
    background-color: #EEF2F8;
    border-radius: 5px;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
}

.home-section-statement p {
    margin: 5px;
}

.home-section-statement img {
    width: 80px;
    margin-bottom: 10px;
}

.home-section-statement-1 {
    grid-area: home-section-statement-1;
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
}

.home-section-statement-1 div {
    padding: 25px;
}

.home-section-statement-2 {
    grid-area: home-section-statement-2;
    text-align: center;
}

.home-section-statement-3 {
    grid-area: home-section-statement-3;
    text-align: center;
}

.home-section-image {
    grid-area: home-section-image;
    width: 50vw;
    margin: auto;
}

.home-section-image img {
    width: 90%;
}

@media all and (max-width:900px) {
    .home {
        grid-template-areas:
            'home-section-image'
            'home-section-statement-1'
            'home-section-statement-2'
            'home-section-statement-3';
        grid-gap: 10px;
        margin-top: initial;
    }

    .home-section-statement-1 div {
        padding: 15px;
    }

    .home-section-statement-1 h1 {
        font-size: 20px;
    }

    .home-section-statement-1 .font-marketing-fontlarge {
        font-size: larger;
        padding: 5px !important;
    }

    .home-section-image {
        width: 65%;
    }
}


/** PORTFOLIO PAGE **/

.portfolio {
    width: 75vw;
    margin: auto;
}

.portfolio-sub-title {
    text-align: center;
    padding: 20px;
}

.portfolio-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.portfolio-section-text {
    width: 25vw;
    margin: auto;
    margin-top: 0;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 5px;

}

.portfolio-section-text-margin {
    margin-top: 75px;
}

.portfolio-section-image img {
    width: 100%;
    max-height: 250px;
    border: 1px solid #333;
}

.portfolio-section-title {
    font-size: smaller;
    font-weight: bold;
    color: #333;
}

.portfolio-section-divider {
    background-color: #dcdcdc;
    margin: 5px;
    padding: 5px;
    text-align: center;
    font-size: 40px;
    font-family: system-ui;
}

@media all and (max-width: 1000px) {
    .portfolio {
        width: auto;
    }

    .portfolio-section {
        flex-direction: column;
        height: auto;
    }

    .portfolio-section-text {
        width: 100%;
        order: 2;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .portfolio-section-text-margin {
        margin-top: inherit;
    }

    .portfolio-section-image {
        width: 100%;
        order: 1;
        text-align: center;
    }

    .portfolio-section-image img {
        width: 90%;
        max-width: 250px;
        min-height: 165px;
    }
}


/** ABOUT PAGE **/

.about {
    width: 75vw;
    margin: auto;
    margin-top: 25px;
}

.about-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

/** The floating squares */
.about .square {
    position: absolute;
    width: 100px;
    height: 80px;
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
    padding: 20px 10px 10px 10px;
    color: #fff;
    font-family: system-ui;
    font-size: 25px;
    animation: float 15s ease-in-out infinite;
    opacity: 0.2;
}

.about .square1 {
    background-color: #036b81;
    top: 100px;
    left: 50vw;
    animation-delay: 0s;
}

.about .square2 {
    background-color: #036b81;
    top: 250px;
    left: 60vw;
    animation-delay: 1s;
}

.about .square3 {
    background-color: #036b81;
    top: 400px;
    left: 70vw;
    animation-delay: 2s;
}

.about .square4 {
    background-color: #036b81;
    top: 500px;
    left: 0;
    animation-delay: 3s;
}

.about .square5 {
    background-color: #036b81;
    top: 650px;
    left: 10vw;
    animation-delay: 4s;
}

.about .square6 {
    background-color: #036b81;
    top: 800px;
    left: 20vw;
    animation-delay: 2s;
}

.about .square7 {
    background-color: #036b81;
    top: 1000px;
    left: 50vw;
    animation-delay: 3s;
}

.about .square8 {
    background-color: #036b81;
    top: 1150px;
    left: 60vw;
    animation-delay: 4s;
}

.about .square9 {
    background-color: #036b81;
    top: 1300px;
    left: 70vw;
    animation-delay: 3s;
}

.about .square10 {
    background-color: #036b81;
    top: 1400px;
    left: 0;
    animation-delay: 4s;
}

.about .square11 {
    background-color: #036b81;
    top: 1550px;
    left: 10vw;
    animation-delay: 3s;
}

.about .square12 {
    background-color: #036b81;
    top: 1700px;
    left: 20vw;
    animation-delay: 4s;
}

@keyframes float {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(20vw, 0);
    }

    100% {
        transform: translate(0, 0);
    }
}

.about .about-text-section {
    background-color: #EEF2F8;
    padding: 30px;
    width: 50%;
    margin: auto;
    margin-top: 0;
    margin-bottom: 100px;
    border-radius: 5px;
}

.about .about-text-image {
    width: 50%;
    margin: auto;
    margin-top: 0;
}

@media all and (max-width: 1000px) {
    .about {
        width: 100%;
    }

    .about .square {
        opacity: 0.1;
    }

    .about .about-text-section {
        width: 100%;
        height: initial;
        margin-bottom: 10px;
    }

    .about .about-text-image {
        width: 20%;
        margin: auto;
        margin-top: 0;
    }

}

/** CODE CHAMBERS PAGE */
.code-chambers {
    width: 75vw;
    margin: auto;
    padding: 10px;
    margin-top: 20px;
}

.code-chambers .code-chambers-section {
    padding-top: 10px;

}

.code-chambers .code-chambers-video {
    width: 500px;
    height: 350px;
}

/** CODE CHAMBERS PAGE BLOG LIST */
.code-chambers .blog-list {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.code-chambers .blog-item {
    margin-bottom: 20px;
}

.code-chambers .blog-title {
    color: #333;
    font-size: 24px;
    margin-bottom: 5px;
}

.code-chambers .blog-date {
    color: #666;
    font-size: 14px;
    margin-bottom: 10px;
}

.code-chambers .blog-summary {
    color: #444;
    font-size: 16px;
    line-height: 1.5;
}

.code-chambers .read-more {
    display: inline-block;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    margin-top: 10px;
    transition: color 0.3s ease;
}

.code-chambers .read-more:hover {
    color: #036b81;
}

/** CODE CHAMBERS PAGE ARTICLE */
.code-chambers header {
    background-color: #036b81;
    color: #fff;
    padding: 20px;
    text-align: center;
}

.code-chambers .article-title {
    margin: 0;
    font-size: 32px;
}

.code-chambers .article-meta {
    margin-top: 5px;
    font-size: 14px;
}

.code-chambers article {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.code-chambers .intro {
    font-size: 18px;
    line-height: 1.6;
}

.code-chambers .quote {
    margin: 20px 0;
    padding: 10px;
    background-color: #f0f0f0;
    border-left: 5px solid #036b81;
    font-style: italic;
}

.code-chambers article p {
    font-size: 16px;
    line-height: 1.6;
}


@media all and (max-width: 1000px) {
    .code-chambers {
        width: 100%;
    }

    .code-chambers .code-chambers-video {
        width: 300px;
        height: 175px;
    }
}


/** CONTACT PAGE */
.contact {
    width: 75vw;
    margin: auto;
    padding: 10px;
    margin-top: 20px;
}

.contact .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.contact .container .form-group {
    margin-bottom: 20px;
}

.contact .container label {
    display: block;
    margin-bottom: 5px;
}

.contact .container input,
textarea {
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.contact textarea {
    height: 250px;
}

.contact .container button {
    padding: 10px 20px;
    background-color: #036b81;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    border: 1px solid #ffffff;
}

.contact .container button:hover {
    background-color: #05a7c7;
}

.contact .success {
    color: green;
}

.contact .error {
    color: red;
}


@media all and (max-width: 1000px) {
    .contact {
        width: 100%;
    }

}


/** SERVICES PAGE **/

.services {
    width: 75vw;
    margin: auto;
    margin-top: 40px;
}

.services .services-section {
    margin: 10px;
    margin-top: 30px;
    border-radius: 5px;
    padding: 10px;
    background-color: #EEF2F8;
    display: flex;
}

.services .services-section.dark {
    background-color: #036b81;
}

.services .services-section div {
    padding: 10px;
}

.services .services-section img {
    width: 400px;
}

.services .services-section h1, .services .services-section h3 {
    margin-top: 0;
}

.services .services-section.dark h1, .services .services-section.dark h3, .services .services-section.dark p, .services .services-section.dark a {
    color: #fff;
}

@keyframes zoomIn {
    from {
        transform: scale(0) rotate(45deg);
        transform-origin:  top left; /* 
        transform: scale(0); /* Start from no scale (invisible) */
        opacity: 0; /* Start from transparency */
    }
    to {
        transform: scale(1); /* Zoom in to normal scale */
        opacity: 1; /* Fully opaque */
    }
}

.zoom-in {
    animation: zoomIn 2000ms ease; /* Adjust animation duration and timing function */
}


@media all and (max-width: 1000px) {
    .services {
        width: 100%;
    }

    .services .services-section {
        display: block;
    }
    
    .services .services-section img {
        width: 100%;
        margin: auto;
    }
}

